import "./SidebarBtn.css";

import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { CircleButton } from "shared/ui/CircleButton";
import { rmdIcons } from "shared/icons";

type Props = {
    action: () => void;
    isVisible: boolean;
    isAuth: boolean;
};

export const SidebarBtn = observer(({ action, isVisible, isAuth }: Props) => (
    isVisible
        ? <CircleButton
            action={action}
            className="sidebar-btn"
            children={rmdIcons.sidebar}
            hint={isAuth ? "Боковое меню" : "Необходима авторизация"}
        />
        : <Fragment />
));