import './Sidebar.css';

import { observer } from "mobx-react-lite";
import cn from 'classnames';

import { useClickOutside } from 'shared/hook/useClickOutside';
import { SongList, SongFavoriteModel } from "entities/Song";
import { FavouriteModel } from "entities/Favourite";

import { SidebarCloseBtn } from './ui/SidebarCloseBtn/SidebarCloseBtn';

type Props = {
  sidebarState: boolean;
  onSidebarClose: () => void;
  topTracks: SongFavoriteModel[];
  historyTracks?: SongFavoriteModel[];
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
  isCustomStation: boolean;
};

export const Sidebar = observer(({
  sidebarState,
  onSidebarClose,
  topTracks,
  historyTracks,
  likedSongs,
  handleNonAuthAction,
  isAuth,
  isCustomStation
}: Props) => {
  const outsideClickRef = useClickOutside(() => onSidebarClose());

  return (
    <div
      ref={outsideClickRef}
      className={cn("sidebar", { "sidebar_opened": sidebarState})}
    >
      <div className="sidebar__content">
        {topTracks.length > 0 && <SongList
          items={topTracks}
          likedSongs={likedSongs}
          handleNonAuthAction={handleNonAuthAction}
          isAuth={isAuth}
          title={isCustomStation ? "Мои треки" : `Топ ${topTracks.length}`}
          headerChildren={<SidebarCloseBtn action={onSidebarClose}/>}
      />}
      {(!!historyTracks && !isCustomStation) &&
        <SongList
          items={historyTracks.length <= 5
            ? historyTracks
            : historyTracks.splice(0, 4)
          }
          likedSongs={likedSongs}
          handleNonAuthAction={handleNonAuthAction}
          isAuth={isAuth}
          title="Последние 5 треков"
        />
      }
      </div>
    </div>
  );
});