import { StationType } from "../config/StationType";
import { StationModel } from "./StationModel";
import { CustomStationModel } from "./CustomStationModel";

export function getPrevStation(): StationModel | CustomStationModel | undefined {
    const prevStationString = localStorage.getItem(StationType.PREVIOUS);
    if (!prevStationString) return;

    const prevStation: StationModel | CustomStationModel = JSON.parse(prevStationString);

    if (!prevStation) {
        return undefined;
    }

    return prevStation;
}