import { observer } from "mobx-react-lite";
import { searchQuery } from '../../shared/lib/search-query';
import { RmdPage } from '../RmdPage';
import './PageNotFound.css';

export const PageNotFound = observer(() => {
    /**
     * @TODO: Доделать вывод запроса на страницу
     */
    // const query: { path?: string } = searchQuery(window.location.search);
    return (
        <RmdPage
            headerLeft={""}
            headerRight={""}
            body={
                <div className="page-not-found">
                    <p className="page-not-found__title">Страница не найдена...</p>
                    {/*<p className="page-not-found__reason">адрес:*/}
                    {/*    <span className="page-not-found__query">{' ' + query.path}</span>*/}
                    {/*</p>*/}
                </div>
            }
        />
    );
});