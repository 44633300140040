import { useEffect, useState } from "react";

export function useScreenMode(){
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenMode = () => setTimeout(() => {
            if (window.screen.width > 1024) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
        }, 1000);

        window.addEventListener("load", checkScreenMode);

        return () => {
            window.removeEventListener("load", checkScreenMode);
        }
    }, []);

    return isMobile;
}