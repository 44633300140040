import { ReactNode } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";

type Props = {
    content?: ReactNode;
    children: JSX.Element;
} & Exclude<TippyProps, "content">;

export function TippyWrapper({ content, children, ...tippyProps }: Props) {
    if (!content) {
        return children;
    }

    return (
        <Tippy {...tippyProps} content={content}>
            { children }
        </Tippy>
    );
}