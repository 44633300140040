import "./ResetForm.css";

import { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import { Form } from "shared/ui/Form";
import { StringInput } from "shared/ui/StringInput";
import { Button } from "shared/ui/Button";

import { AuthStore } from "./model/AuthStore";
import { IPassRequestData } from "./types/IPassRequestData";

type Props = {
  store: AuthStore;
};

export const ResetForm = observer(({ store }: Props) => {
    const [userData, setUserData] = useState<IPassRequestData>({
        email: ""
    });
    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => setUserData((prev) => ({
        ...prev,
        [evt.target.name]: evt.target.value
    }));
    const handleSubmitForm = async (evt: FormEvent) => {
        evt.preventDefault();
        await store.requestRecover(userData);
        store.closeForm();
        setUserData({ email: "" });
    };

   return (
       <div className="reset-form">
           <Form
               formTitle="Восстановление пароля"
               onFormSubmit={handleSubmitForm}
           >
               <Fragment>
                   <StringInput
                       type="email"
                       name="email"
                       placeholder="email"
                       onChange={onInputChange}
                       minLength={8}
                       maxLength={32}
                   />
                   <Button
                       type="primary"
                       actionType="submit"
                       size="large"
                       text="Восстановить"
                   />
               </Fragment>
           </Form>
       </div>
   )
});