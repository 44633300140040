export interface IEntityMediaModelConfig {
    duration: number;
    is_request: boolean;
    played_at: number;
    playlist: string;
    sh_id: number;
    streamer: string;
}

export abstract class EntityMediaModel {
    duration: number = 0;

    isRequest: boolean = false;

    playedAt: number = 0;

    playlist: string = '';

    shId: number = 0;

    streamer: string = '';

    protected constructor(config: IEntityMediaModelConfig) {
        this.duration = config.duration;
        this.isRequest = config.is_request;
        this.playedAt = config.played_at;
        this.playlist = config.playlist;
        this.shId = config.sh_id;
        this.streamer = config.streamer;
    }
}