import "./SongList.css";

import { observer } from "mobx-react-lite";
import { FavouriteModel } from "entities/Favourite";

import { SongFavoriteModel } from "../../model/SongFavoriteModel";
import { Song } from "../Song/Song";

type Props = {
  items: SongFavoriteModel[];
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
  title: string;
  headerChildren?: JSX.Element;
};

export const SongList = observer(({
  items,
  likedSongs,
  handleNonAuthAction,
  isAuth,
  title,
  headerChildren
}: Props) => {
  return (
    <ul className="song-list">
      <div className="song-list__header">
        <h2 className="song-list__title">{title}</h2>
        {headerChildren}
      </div>
      {items.map(item => <Song
        key={item.id}
        song={item}
        likedSongs={likedSongs}
        handleNonAuthAction={handleNonAuthAction}
        isAuth={isAuth}
      />)}
    </ul>
  );
});