import './Station.css';

import { observer } from "mobx-react-lite";
import cn from 'classnames';
import { RmdIcon } from 'shared/ui/RmdIcon';

import { stationIcons } from '../icons/stationIcons';
import { StationModel } from '../model/StationModel';
import { CustomStationModel } from "../model/CustomStationModel";
import { StationLayout } from "./StationLayout";

type Props = {
    model: StationModel | CustomStationModel;
    isCurrent: boolean;
    handleStationClick: (item: StationModel | CustomStationModel) => void;
    isAuth: boolean;
};

export const Station = observer(({
    model, isCurrent, handleStationClick, isAuth
}: Props) => {
    const inactiveCustomStation = model.isCustom && !isAuth;
    const className = cn(
        'station',
        {'station_active': isCurrent},
        { "station_inactive": !model.isAvailable }
    );
    const handleSetCurrent = () => handleStationClick(model);
    const stationIcon = model.isAvailable
        ? (isCurrent
            ? `${model.icon + 'Active'}`
            : model.icon
        )
        : `${model.icon + 'Inactive'}`;

    return (
        inactiveCustomStation ? (
            <li
                className={className}
                onClick={handleSetCurrent}
            >
                <RmdIcon
                    customCollection={stationIcons}
                    type={stationIcon}
                />
                <p className="station__name">{model.title}</p>
            </li>
            ) : (
                <StationLayout
                    isActive={model.isAvailable}
                    stationName={model.name}
                    stationIcon={stationIcon}
                    className={className}
                    action={handleSetCurrent}
                />
            )
    );
});