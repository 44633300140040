import axios, { AxiosError, AxiosResponse } from "axios";
import {BACKEND_URL, DOWNLOAD_PATH, TRACKS_PATH} from "shared/const/backendPaths";
import { getCurrentToken } from "shared/lib/getCurrentToken";

interface ISongDownloadConfig {
  songPath: string;
  storageId?: number
}

export async function downloadSong(config: ISongDownloadConfig) {
  const token = getCurrentToken();
  if (!token) return;

  try {
    const { songPath, storageId } = config;
    const requestData = new FormData();
    requestData.append("path", songPath);
    if (storageId) {
      requestData.append("storage_location_id", String(storageId));
    }
    const result: AxiosResponse<{
      code: number;
      message: string;
      url: string;
    }> = await axios.post(BACKEND_URL + TRACKS_PATH + DOWNLOAD_PATH, requestData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (result && result.status === 200) {
      return result.data.url;
    }
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      console.log(`Ошибка при скачивании трека: ${err}`);
    }
  }
}