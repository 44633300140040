import "./HeaderAccount.css";

import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { RegisterBtn, RegisterForm } from "features/Register";
import { AuthBtn, AuthForm, AuthLogOut as LogoutBtn } from "features/Auth";
import { ResetForm } from "features/Auth/ResetForm";
import { AuthorizationProcess } from "processes";
import { Modal } from "shared/ui/Modal";
import { CircleButton } from "shared/ui/CircleButton";
import { rmdIcons } from "shared/icons";
import { Preloader } from "shared/ui/Preloader";

type Props = {
    authProcess: AuthorizationProcess;
    isMobile?: boolean;
};

export const  HeaderAccount = observer(({
    authProcess,
    isMobile = false
}: Props) => {
    const {
        registerStore, authStore
    } = authProcess;
    const { isAuth, isLoading, currentUser } = authStore;

    const renderNotAuth = () => {
        return (
            <Fragment>
                <RegisterBtn action={() => registerStore.openForm()} isMobile={isMobile} />
                <AuthBtn action={() => authStore.openForm()} isMobile={isMobile} />
            </Fragment>
        );
    };
    const renderIsAuth = () => {
        return (
            <Fragment>
                {!isMobile && <CircleButton
                    action={() => {}}
                    children={rmdIcons.account}
                    hint={<p className="account__email">{currentUser?.email ?? "почта не указана"}</p>}
                />}
                <LogoutBtn action={async () => await authStore.logout()} />
            </Fragment>
        );
    };

    return (
        <div className="header-account">
            { isLoading
                ? <Preloader />
                : (isAuth ? renderIsAuth() : renderNotAuth())
            }
            { registerStore.isFormActive &&
                <Modal
                    onClose={() => registerStore.closeForm()}
                    children={
                        <RegisterForm store={registerStore} />
                    }
                />
            }
            { authStore.isFormActive &&
                <Modal
                    onClose={() => authStore.closeForm()}
                    children={authStore.needRecover
                        ? (<ResetForm store={authStore} />)
                        : (<AuthForm store={authStore} />)
                    }
                />
            }
        </div>
    );
});