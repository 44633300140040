import { StationType } from "../config/StationType";
import { CustomStationModel } from "./CustomStationModel";
import { StationModel } from "./StationModel";

export function getCurrentStation(): StationModel | CustomStationModel | undefined {
    const stationString = localStorage.getItem(StationType.CURRENT);
    if (!stationString) return undefined;

    const station: StationModel | CustomStationModel = JSON.parse(stationString);

    if (station) {
        return station;
    }

    return undefined;
}