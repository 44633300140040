import './Home.css';

import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useScreenMode } from "app";

import { AppStore } from "app/model/AppStore";
import { HeaderAccount } from 'widgets/HeaderAccount';
import { SidebarStore } from "widgets/Sidebar/store/SidebarStore";
import { Sidebar, SidebarBtn } from "widgets/Sidebar";
import { Player } from "widgets/Player";
import { CustomStationModel, StationModel } from "entities/Station";
import { StationSwitch } from 'features/SwitchStation';
import { runPlayAfterSwitch } from "features/PlayerControl";
import { NotificationList } from "features/Notification";
import { useStoreFactory } from "shared/hook/useStoreFactory";
import { RmdLogo } from "shared/ui/RmdLogo";

import { RmdPage } from '../RmdPage';

export const Home = observer(() => {
  const { appMediaStore, authorizationProcess } = AppStore.getShared();
  const {
    nowPlayingStore,
    stationSwitchStore,
    favouriteStore,
    playerControlStore,
    songHistoryStore
  } = appMediaStore;
  const isMobile = useScreenMode();
  const sidebarStore = useStoreFactory({
    createStore: () => new SidebarStore()
  });

  const { sidebarOpened } = sidebarStore;
  const { authStore, notificationStore } = authorizationProcess;
  const { isAuth } = authStore;
  const { currentStation, topTracks, myTracks } = stationSwitchStore;
  const isCustomStation = currentStation?.isCustom ?? false;
  const handleNonAuthAction = () => authStore.openForm();

  return (
    <Fragment>
      <RmdPage
        headerLeft={""}
        headerMiddle={<RmdLogo />}
        headerRight={
          <HeaderAccount authProcess={authorizationProcess} isMobile={isMobile} />
        }
        body={
          <main className="home">
            <StationSwitch
              isAuth={isAuth}
              actionAfterSwitch={(
                model: StationModel | CustomStationModel,
                currentStation: StationModel | CustomStationModel | null
              ) => runPlayAfterSwitch(model, currentStation)}
            />
            <SidebarBtn
              action={isAuth
                ? () => {
                  sidebarStore.openSidebar();
                  favouriteStore.getLiked();
                }
                : handleNonAuthAction
              }
              isVisible={!isMobile}
              isAuth={isAuth}
            />
            <Sidebar
              sidebarState={sidebarOpened}
              onSidebarClose={() => sidebarStore.closeSidebar()}
              topTracks={isCustomStation ? myTracks : topTracks}
              historyTracks={songHistoryStore.songHistory}
              likedSongs={favouriteStore.likedSongs}
              handleNonAuthAction={handleNonAuthAction}
              isAuth={isAuth}
              isCustomStation={isCustomStation}
            />
            <NotificationList store={notificationStore} />
          </main>
        }
        footer={
          <Player
              playerControlStore={playerControlStore}
              nowPlayingStore={nowPlayingStore}
              stationSwitchStore={stationSwitchStore}
              favouriteStore={favouriteStore}
              handleNonAuthAction={handleNonAuthAction}
              isAuth={isAuth}
              isMobile={isMobile}
              isCustomStation={isCustomStation}
          />
        }
      />
      <Outlet />
    </Fragment>
  );
});