import { observer } from "mobx-react-lite";
import { ITabMap } from './types/ITabMap';
import { TabItem } from './TabItem';
import './Tabs.css';

type Props = {
    isAuth: boolean;
}

export const Tabs = observer(({ isAuth }: Props) => {
    const tabMap: ITabMap = {
        playlist: {
            tabName: 'Мой плейлист',
            action: () => 1,
            active: isAuth
        },
        profile: {
            tabName: 'Профиль',
            action: () => 1,
            active: isAuth
        },
        plans: {
            tabName: 'Тарифы',
            action: () => 1,
            active: isAuth
        },
        DJSettings: {
            tabName: 'DJ Setting',
            action: () => 1,
            active: false
        },
        support: {
            tabName: 'Поддержка',
            action: () => 1,
            active: isAuth
        }
    };

    const tabConfig = Object.entries(tabMap).map(([key, item]) => ({ key, item }));

    return (
        <ul className="tabs">
            {
                tabConfig.map(({ key, item }) => <TabItem
                    key={key}
                    tabName={item.tabName}
                    action={item.action}
                    active={item.active}
                />
            )}
        </ul>
    );
});