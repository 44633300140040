import { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlay } from '../ModalOverlay/ModalOverlay';
import { IconButton } from '../IconButton';
import { RmdIcon } from '../RmdIcon';
import "./Modal.css";

type Props = {
    onClose: () => void;
    children: JSX.Element | null;
};

export const Modal = ({ onClose, children }: Props) => {
    const handleClose = () => onClose();

    const closeModalByEsc = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') return handleClose();
    };

    useEffect(() => {
        document.addEventListener('keydown', closeModalByEsc)
        return () => {
            document.removeEventListener('keydown', closeModalByEsc)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ReactDOM.createPortal(
        <Fragment>
            <div className="modal">
                <span className="modal__close-btn">
                    <IconButton onClick={handleClose}>
                        <RmdIcon type="closeIcon" />
                    </IconButton>
                </span>
                {children}
            </div>
            <ModalOverlay
                closeModal={handleClose}
            />
        </Fragment>,
        document.getElementById("modals") as HTMLDivElement
    );
};