import { action, makeObservable, observable } from "mobx";
import axios, {AxiosResponse} from "axios";
import {BACKEND_URL, ACCOUNT_VERIFY, REGISTER_PATH} from "../../../shared/const/backendPaths";
import { EventEmitter } from "../../../shared/lib/EventEmitter";
import { IUserData, IRegisterResponseData } from "../types";
import {IRegisterErrorData} from "../types/IRegisterErrorData";
import {AxiosError} from "axios";

type EntityListEvents = {
    "registerSuccess": (data: IRegisterResponseData) => void;
    "registerError": () => void;
    "verificationCompleted": (message: string) => void;
};

export class RegisterStore extends EventEmitter<EntityListEvents> {
    isFormActive: boolean;

    constructor() {
        super();

        this.isFormActive = false;

        makeObservable(this, {
            isFormActive: observable,
            openForm: action,
            closeForm: action
        })
    }

    async register(data: IUserData) {
        const {
            firstName, lastName, email, password, passwordConfirmation
        } = data;

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation
        };

        axios.post(BACKEND_URL + REGISTER_PATH, userData)
            .then((res: AxiosResponse<IRegisterResponseData, IRegisterErrorData>) => {
                if (res && res.data) {
                    this.emit("registerSuccess", res.data);
                }
            })
            .catch((err: IRegisterErrorData) => {
                if (err && err.response.status === 422) {
                    this.emit("registerError");
                    console.error("Указанный email уже существует!", err);
                }
            });
    }

    async verifyAccount() {
        const token = localStorage.getItem("verifyToken");

        if (!token) return;

        try {
             const result: AxiosResponse<{ message: string; user: IUserData; }> = await axios
            .get(`${ACCOUNT_VERIFY}/${token}`);

            if (result && result.status === 200) {
                this.emit("verificationCompleted", `вы успешно верифицровали почту ${result.data.user.email}`);
                return result.data.user.email;
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                console.log("Ошибка при верификации почты: ", error)
            }
        }
    }

    openForm() {
        this.isFormActive = true;
    }

    closeForm() {
        this.isFormActive = false;
    }
}