import "./PasswordReset.css";

import { Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppStore } from "app";
import { RmdLogo } from "shared/ui/RmdLogo/RmdLogo";
import { pagePaths } from "shared/const/pagePaths";
import { useScreenMode } from "app/lib/useScreenMode";
import { NewPassForm } from "features/Auth/NewPassForm";
import { NotificationList } from "features/Notification";

import { RmdPage } from "../RmdPage";

export const PasswordReset = () => {
    const { authorizationProcess: { authStore, notificationStore, ...rest } } = AppStore.getShared();
    const isMobile = useScreenMode();
    const navigate = useNavigate();

    return (
        <Fragment>
            <RmdPage
                headerLeft={""}
                headerMiddle={
                    <RmdLogo isMobile={isMobile} onClick={() => navigate(pagePaths.HOME_ROUTE)} />
                }
                body={
                    <main className="password-reset-page">
                        <NewPassForm store={authStore} />
                        <NotificationList store={notificationStore} />
                    </main>
                }
            />
            <Outlet />
        </Fragment>
    );
}