import { Button } from "../../shared/ui/Button";

type Props = {
    action: () => void;
    isMobile: boolean;
};

export const AuthBtn = ({ action, isMobile }: Props) => {
    return (
        <Button
            type={isMobile ? "mobile" : "secondary"}
            action={action}
            size="medium"
            text="Войти"
        />
    );
};