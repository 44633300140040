import Tippy from '@tippyjs/react';
import cn from 'classnames';
import { ITabOption } from './types/ITabOption';
import './TabItem.css';

export const TabItem = ({ tabName, action, active }: ITabOption) => {
    const className = cn(
        "tab-item",
        { "tab-item_inactive": !active }
    );

    const renderTab = () => {
        if (active) {
            return (
                <li onClick={action} className={className}>
                    {tabName}
                </li>
            );
        } else {
            return (
                <Tippy content={!active && "Не доступно"}>
                    <li onClick={action} className={className}>
                        {tabName}
                    </li>
                </Tippy>
            );
        }
    };

    return (
        renderTab()
    );
};