import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import "./PlayerTrackInfo.css";


export const PlayerTrackInfo = observer(({song}: any) => {
    return (
        <Fragment>
            <div className="track-cover">
                <img src={song.art} alt="track cover" />
            </div>

            <div className="track-name">
                <p className="track-name__artist">{song.artist}</p>
                <p className="track-name__song">{song.title}</p>
            </div>
        </Fragment>
    );
});
