import "./ControlBtn.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { NowPlayingModel } from "entities/NowPlaying";
import { CircleButton } from "shared/ui/CircleButton";
import { rmdIcons } from "shared/icons";

type Props = {
    icon: keyof typeof rmdIcons;
    action: Function;
    isAvailable: boolean;
    checkIsActive: (model: NowPlayingModel) => boolean;
    isAuth: boolean;
    model: NowPlayingModel;
};

export const ControlBtn = observer(({
    icon,
    action,
    checkIsActive,
    isAvailable,
    isAuth,
    model,
}: Props) => {
    const [isActive, setIsActive] = useState(() => checkIsActive(model) ?? false);
    const [iconName, setIconName] = useState(rmdIcons[icon]);

    const handleChangeState = async () => {
        if (!(isAuth && isAvailable)) return;
        action();
        setIsActive(!isActive);
    };

    useLayoutEffect(() => {
        if (isAvailable) {
            if (!isActive) {
                setIconName(rmdIcons[icon]);
            } else {
                setIconName(rmdIcons[`${icon}Active` as keyof typeof rmdIcons]);
            }
        } else {
            setIconName(rmdIcons[`${icon}Inactive` as keyof typeof rmdIcons]);
        }
    }, [isActive, isAvailable, isAuth]);

    useEffect(() => {
        setIsActive(checkIsActive(model));
    }, [model]);

    return (
        <div className="control-btn">
            <CircleButton
                action={handleChangeState}
                children={iconName}
                hint={!isAvailable ? "Не доступно" : undefined}
            />
        </div>
    );
});