import { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { pageRoutes } from "pages/pageRoutes";
import { baseURL } from "shared/config";
import { pagePaths } from "shared/const/pagePaths";
import { PageNotFound } from "pages/PageNotFound";

import { AppStore } from "./model/AppStore";

export const AppRouter = observer(() => {
    const { authorizationProcess: { authStore} } = AppStore.getShared();
    const location = useLocation();
    const state = location.state && location.state.from;

    useEffect(() => {
        authStore.userCheck();
    },[]);

    return (
        <Routes location={state || location}>
            <Route path="/" element={<Outlet />}>
                {Object.entries(pageRoutes).map(
                    ([path, Component]) => <Route
                        key={path}
                        path={baseURL + path}
                        element={<Component />}
                        index={path === "/"}
                    />
                )}
            </Route>
            <Route
                path={pagePaths.UNKNOWN_ROUTE}
                element={<PageNotFound />}
            />
        </Routes>
    );
});