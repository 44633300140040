import { observer } from "mobx-react-lite";
import { ControlBtn } from "./ui/ControlBtn/ControlBtn";
import { PlayerControlStore } from "./store/PlayerControlStore";
import { StationSwitchStore } from "../SwitchStation";
import { RefObject, useEffect } from "react";
import "./PlayerControl.css";

type Props = {
  audioRef: RefObject<HTMLAudioElement>;
  svgRef: RefObject<HTMLElement>;
  stationSwitchStore: StationSwitchStore;
  playerControlStore: PlayerControlStore;
};

export const PlayerControl = observer(
  ({ audioRef, stationSwitchStore, playerControlStore }: Props) => {
    const keyboardControl = (evt: KeyboardEvent) => {
      if (evt.code === 'Space') return playerControlStore.togglePlay(!playerControlStore.isPlaying, stationSwitchStore.currentStation?.audioUrl);
      if (evt.code === 'KeyL') return loadNext();
      if (evt.code === 'KeyR') return loadPrev();
      if (evt.code === 'ArrowLeft') return console.log("ArrowLeft");
      if (evt.code === 'ArrowRight') return console.log("ArrowRight");
    };

    useEffect(() => {
      document.addEventListener('keydown', keyboardControl)
      return () => {
          document.removeEventListener('keydown', keyboardControl)
      }
    }, [])

    const loadPrev = () => {
      stationSwitchStore.switchStation("previous");
      playerControlStore.togglePlay(true, stationSwitchStore.currentStation?.audioUrl);
    };

    const loadNext = () => {
      stationSwitchStore.switchStation("next");
      playerControlStore.togglePlay(true, stationSwitchStore.currentStation?.audioUrl);
    };

    return (
      <div className="player-controls">
        <ControlBtn
          type="secondary"
          icon="prevButton"
          action={() => loadPrev()}
        />
        <ControlBtn
          type="primary"
          icon={playerControlStore.isPlaying ? "pauseButton" : "playButton"}
          action={() => playerControlStore.togglePlay(!playerControlStore.isPlaying, stationSwitchStore.currentStation?.audioUrl)}
        />
        <ControlBtn
          type="secondary"
          icon="nextButton"
          action={() => loadNext()}
        />
        <audio id="audio" ref={audioRef}/>
      </div>
    );
  }
);
