import './StationSwitch.css';

import { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CustomStationModel, StationModel, Station, getPrevStation, getCurrentStation } from 'entities/Station';
import { StationUnionType } from "entities/Station/types";
import { getCurrentToken } from "shared/lib/getCurrentToken";
import { pagePaths } from "shared/const/pagePaths";

import { StationSwitchStore } from "./model/StationSwitchStore";

type Props = {
    isAuth: boolean;
    actionAfterSwitch: (model: StationUnionType, customStation: StationUnionType | null) => void;
};

export const StationSwitch = observer(({
    isAuth,
    actionAfterSwitch
}: Props) => {
    const navigate = useNavigate();
    const ref = useRef<HTMLUListElement>(null);
    const stationSwitchStore = StationSwitchStore.getShared();
    const { stationList, currentStation} = stationSwitchStore;

    const handleSetStation = (model: StationModel | CustomStationModel) => {
        if (!model.isAvailable) return;
        stationSwitchStore.setCurrent(model);
        actionAfterSwitch(model, currentStation);
    };

    useEffect(() => {
        stationSwitchStore.setupStations();
    }, []);

    useEffect(() => {
        if (isAuth) {
            stationSwitchStore.getStationList();
        }
    }, [isAuth]);

    useEffect(() => {
        const currentToken = getCurrentToken();
        const persistCurrentStation = getCurrentStation();
        const persistPreviousStation = getPrevStation();
        if (!(persistCurrentStation && persistPreviousStation)) return;

        if (!currentToken) {
            if (!persistCurrentStation.isCustom) {
                navigate(pagePaths.station + "/" + (persistCurrentStation.name ?? "deep"));
                stationSwitchStore.setCurrent(persistCurrentStation);
            } else if (!persistPreviousStation.isCustom) {
                navigate(pagePaths.station + "/" + (persistPreviousStation.name ?? "deep"));
                stationSwitchStore.setCurrent(persistPreviousStation);
            }
        }
    }, [isAuth]);

    return (
        <ul ref={ref} className="station-switch">
            {stationList.map((item: StationModel) => <Station
                key={item.title}
                model={item}
                isCurrent={currentStation?.id === item.id}
                handleStationClick={handleSetStation}
                isAuth={isAuth}
            />)}
        </ul>
    );
});