import axios, { AxiosError, AxiosResponse } from "axios";
import { ISongFavoriteResponseConfig, SongFavoriteModel } from "entities/Song";
import { BACKEND_URL, MY_STATION, TRACKS_PATH } from "shared/const/backendPaths";
import { getCurrentToken } from "shared/lib/getCurrentToken";

import { StationModel } from "./StationModel";
import { IStationModelConfig } from "../types";

export class CustomStationModel extends StationModel {
    public readonly icon = "personal";

    public readonly wssCode = null;

    public readonly isCustom = true;

    constructor(backendData: IStationModelConfig) {
        super(backendData);
    }

    static async fetchSongList() {
        const token = getCurrentToken();
        if (!token) return;

        try {
            const result: AxiosResponse<ISongFavoriteResponseConfig[], SongFavoriteModel[]> = await axios.get(BACKEND_URL + TRACKS_PATH + MY_STATION, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (result && result.status === 200) {
                const songList = result.data.map((item) => new SongFavoriteModel(item));
                return result.data;
            }

            return result;
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                console.log(`Ошибка при получании треков кастомной станции: ${error}`);
            }
        }
    }
}
