import { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormTextLink } from "shared/ui/Form";
import { StringInput } from "shared/ui/StringInput";
import { Button } from "shared/ui/Button";
import { pagePaths } from "shared/const/pagePaths";

import { AuthStore } from "./model/AuthStore";

export const NewPassForm = ({ store }: { store: AuthStore}) => {
    const [userData, setUserData] = useState<
        { password: string; passwordConfirmation: string; }
    >({
        password: "",
        passwordConfirmation: ""
    });

    const navigate = useNavigate();
    const location = useLocation();
    const passToken = location.search.slice(7);

    if (passToken) {
        localStorage.setItem("passToken", passToken);
    }

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => setUserData((prev) => ({
        ...prev,
        [evt.target.name]: evt.target.value
    }));

    const handleSubmitForm = async (evt: FormEvent) => {
        evt.preventDefault();

        /**
         * @TODO: доделать валидацию и проверку пароля
         */
        if (userData.password === userData.passwordConfirmation) {
            const result = await store.sendNewPassword({
                password: userData.password,
                password_confirmation: userData.passwordConfirmation
            });

            /*  Если пароль успешно сброшен, редирект на HOME_ROUTE */
            if (result) {
                navigate(pagePaths.HOME_ROUTE);
            }
        }
    };

    return (
        <div className="reset-form">
            <Form
                formTitle="Создание нового пароля"
                onFormSubmit={handleSubmitForm}
            >
                <Fragment>
                    <StringInput
                        type="password"
                        name="password"
                        placeholder="пароль"
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <StringInput
                        type="password"
                        name="passwordConfirmation"
                        placeholder="повторите пароль"
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <FormTextLink
                        actionMessage="Я вспомнил пароль"
                        action={() => navigate(pagePaths.HOME_ROUTE)}
                    />
                    <Button
                        type="primary"
                        actionType="submit"
                        size="large"
                        text="Подтвердить"
                    />
                </Fragment>
            </Form>
        </div>
    );
}