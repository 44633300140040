import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CircleButton } from "../../../../shared/ui/CircleButton";
import { rmdIcons } from "../../../../shared/icons";
import { PlayerControl, PlayerControlStore } from "../../../../features/PlayerControl";
import { StationSwitchStore } from "../../../../features/SwitchStation";
import { SongModel } from "../../../../entities/Song";
import { PlayerTrackInfo } from "../PlayerTrackInfo";
import { VolumeBarStore } from "../../../../features/VolumeBar";
import { VolumeBar } from "../VolumeBar/VolumeBar";
import "./PlayerControls.css";

type Props = {
    playerControlStore: PlayerControlStore;
    stationSwitchStore: StationSwitchStore;
    isMobile: boolean;
    song: SongModel;
    volumeBarStore: VolumeBarStore;
    time: any;
    isCustomStation: boolean;
};

export const PlayerControls = observer(({
  playerControlStore,
  stationSwitchStore,
  isMobile,
  song,
  volumeBarStore,
  time,
  isCustomStation
}: Props) => {
  const [shouldShowInfo, setShouldShowInfo] = useState(false);
  const [shouldShowVolume, setShouldShowVolume] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (!isCustomStation) {
      setRemainingTime(time.remaining);
      intervalId = setInterval(() => {
        setRemainingTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [time, isCustomStation]);


  return (
    <div className="player-controls">
      {isMobile && <CircleButton
        action={() => setShouldShowInfo(!shouldShowInfo)}
        children={rmdIcons.info}
        hint={shouldShowInfo
          ? <PlayerTrackInfo song={song}/>
          : "Инфо о треке"
        }
      />
      }
      <div className="player-controls__wrapper">
        <PlayerControl
          audioRef={playerControlStore.audioRef}
          svgRef={playerControlStore.svgRef}
          stationSwitchStore={stationSwitchStore}
          playerControlStore={playerControlStore}
        />
        <p className="player__station-name">
          {isCustomStation
            ? stationSwitchStore.currentStation?.shortTitle
            : `NEXT TRACK: ${formatTime(remainingTime)}`
          }
        </p>
      </div>
      {isMobile &&
        <CircleButton
          interactive={true}
          action={() => setShouldShowVolume(!shouldShowVolume)}
          children={rmdIcons.volumeMobile}
          hint={<VolumeBar isVertical={isMobile} store={volumeBarStore}/>}
        />
      }
    </div>
  );
});