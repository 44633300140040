import { observer } from "mobx-react-lite";
import { RmdPage } from '../RmdPage';
import { RmdLogo } from '../../shared/ui/RmdLogo/RmdLogo';
import { HeaderAccount } from '../../widgets/HeaderAccount';
import { Tabs } from '../../features/Tabs';
import { AuthorizationProcess } from '../../processes';
import './Profile.css';
import { AppStore } from "../../app";

export const Profile = observer(() => {
    const appStore = AppStore.getShared();
    const { authorizationProcess: { authStore, ...rest } } = AppStore.getShared();

    return (
        <RmdPage
            headerLeft={""}
            headerMiddle={<RmdLogo />}
            headerRight={
                <HeaderAccount authProcess={appStore.authorizationProcess} />
            }
            body={
                <main className="profile-page">
                    <Tabs isAuth={authStore.isAuth} />
                </main>
            }
        />
    );
});