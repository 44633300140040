import uuid from "react-uuid";
import { NotificationStore } from "features/Notification";
import { AuthStore } from "features/Auth";
import { RegisterStore } from "features/Register";
import { IAuthResponseData, ILogoutResponse } from "features/Auth/types";
import { IRegisterResponseData } from "features/Register/types";

export class AuthorizationProcess {
    private static shared: AuthorizationProcess | null;

    registerStore: RegisterStore = new RegisterStore();

    authStore: AuthStore = AuthStore.getShared();

    notificationStore: NotificationStore = new NotificationStore();

    constructor() {
        this.registerStore.on("registerSuccess", (data: IRegisterResponseData) => {
            if (data) {
                const status = data.code === 200 ? "success" : "default";

                this.notificationStore.createNotification({
                    status,
                    title: "Запрос на регистрацию отправлен",
                    body: data.message,
                    id: uuid()
                });
            }
        });

        this.registerStore.on("verificationCompleted", (data: string) => {
            this.notificationStore.createNotification({
                status: "success",
                title: data,
                id: uuid()
            });
        });

        this.registerStore.on("registerError", () => {
            this.notificationStore.createNotification({
                status: "error",
                title: "Указанный email уже существует!",
                id: uuid()
            })
        });

        this.authStore.on("authUserDataReceived", (data: IAuthResponseData) => {
            if (data) {
                if (data.user.email_verified_at === null) {
                    this.notificationStore.createNotification({
                        status: "error",
                        title: "Почта не верифицирована!",
                        body: `Письмо было отправлено на почту: ${data.user.email}`,
                        id: uuid()
                    });
                } else {
                    this.notificationStore.createNotification({
                        status: "success",
                        title: "Успешная авторизация",
                        id: uuid()
                    });
                }
            }
        });

        this.authStore.on("logoutSuccess", (data: ILogoutResponse) => {
            if (data && data.status === 200) {
                this.notificationStore.createNotification({
                    status: "success",
                    title: "Вы вышли из системы",
                    id: uuid()
                });
            }
        });

        this.authStore.on("invalidCredential", () => {
            this.notificationStore.createNotification({
                status: "error",
                title: "Неправильная почта или пароль!",
                id: uuid()
            });
        });

        this.authStore.on("recoverRequested", (message: string) => {
            this.notificationStore.createNotification({
                status: "success",
                title: "Запрос отправлен!",
                body: message,
                id: uuid()
            });
        });

        this.authStore.on("passwordConfirmed", (message: string) => {
            this.notificationStore.createNotification({
                status: "success",
                title: message,
                id: uuid()
            });
        });
    }

    static getShared(): AuthorizationProcess {
        if (this.shared) {
            return this.shared;
        }
        this.shared = new AuthorizationProcess();
        return this.shared;
    }
}