import { CustomStationModel, StationModel } from "entities/Station";
import { PlayerControlStore } from "../store/PlayerControlStore";


export function runPlayAfterSwitch(
    model: StationModel | CustomStationModel,
    currentStation: StationModel | CustomStationModel | null
) {
    const playerControlStore = PlayerControlStore.getShared();

    if (model.id === currentStation?.id) {
        playerControlStore.togglePlay(!playerControlStore.isPlaying, model.audioUrl);
    } else {
        playerControlStore.togglePlay(true, model.audioUrl);
    }
}