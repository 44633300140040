import "./Verify.css";

import {Fragment, useContext, useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import { AppStore } from "app/model/AppStore";
import { RmdLogo } from "shared/ui/RmdLogo/RmdLogo";
import { pagePaths } from "shared/const/pagePaths";
import { useScreenMode } from "app/lib/useScreenMode";

import { RmdPage } from "../RmdPage";

export const Verify = () => {
    const { authorizationProcess: { authStore, notificationStore, ...rest } } = AppStore.getShared();
    const isMobile = useScreenMode();
    const location = useLocation();
    const navigate = useNavigate();
    const { appMediaStore, authorizationProcess } = AppStore.getShared();

    const verifyToken = location.search.slice(7);

    if (verifyToken) {
        localStorage.setItem("verifyToken", verifyToken);
    }

    const verifyAccount = async () => {
        const result = await authorizationProcess.registerStore.verifyAccount();

        if (result) {
            navigate(pagePaths.HOME_ROUTE);
        }
    }

    useEffect(() => {
        verifyAccount();
    }, [])

    return (
        <Fragment>
            <RmdPage
                headerLeft={""}
                headerMiddle={
                    <RmdLogo isMobile={isMobile} onClick={() => navigate(pagePaths.HOME_ROUTE)} />
                }
                body={
                    <main className="password-reset-page">
                        Верификация
                    </main>
                }
            />
            <Outlet />
        </Fragment>
    );
}