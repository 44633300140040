import { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { StationSwitchStore } from "../../features/SwitchStation";
import { FavouriteStore } from "../../entities/Favourite";
import { NowPlayingStore } from "../../entities/NowPlaying";
import { PlayerControlStore } from "../../features/PlayerControl";
import { VolumeBarStore } from "../../features/VolumeBar";
import { PlayerTrackInfo } from "./ui/PlayerTrackInfo";
import { Equalizer } from "../../features/Equalizer";
import { TimeLine } from "../../features/TimeLine";
import { PlayerSongControls } from "./PlayerSongControls/PlayerSongControls";
import { PlayerControls } from "./ui/PlayerControls/PlayerControls";
import { useStoreFactory } from "../../shared/hook/useStoreFactory";
import { VolumeBar } from "./ui/VolumeBar/VolumeBar";
import "./Player.css";

type Props = {
    playerControlStore: PlayerControlStore;
    nowPlayingStore: NowPlayingStore;
    stationSwitchStore: StationSwitchStore;
    favouriteStore: FavouriteStore;
    handleNonAuthAction: () => void;
    isAuth: boolean;
    isMobile: boolean;
    isCustomStation: boolean;
};

export const Player = observer(({
    playerControlStore,
    nowPlayingStore,
    stationSwitchStore,
    favouriteStore,
    handleNonAuthAction,
    isAuth,
    isMobile,
    isCustomStation
}: Props) => {
    const volumeBarStore = useStoreFactory({
        createStore: () => new VolumeBarStore(),
    });
    playerControlStore.audioRef = useRef<HTMLAudioElement>(null);
    playerControlStore.svgRef = useRef<HTMLElement>(null);
    const timeLineRef = useRef<HTMLElement>(null);
    const { nowPlayingData } = nowPlayingStore;
    const { likedSongs } = favouriteStore;
    const type = isCustomStation ? "short" : "full";
    if (!nowPlayingData) return <Fragment/>;

    const time = {
        elapsed: nowPlayingData.elapsed,
        duration: nowPlayingData.duration,
        remaining: nowPlayingData.remaining
    };
    const { song } = nowPlayingData;
    if (!song) return <Fragment />;

    return (
        <Fragment>
            <div className="equalizer-container">
                <Equalizer svgRef={playerControlStore.svgRef}/>
            </div>
            <div className={`player-container player-container_${type}`}>
                {isCustomStation && <TimeLine time={time} timeLineRef={timeLineRef}/>}
                <div className="player">
                    <div className="player__track-info">
                        <PlayerTrackInfo song={song} />
                    </div>
                    <PlayerControls
                        playerControlStore={playerControlStore}
                        stationSwitchStore={stationSwitchStore}
                        isMobile={isMobile}
                        song={song}
                        volumeBarStore={volumeBarStore}
                        isCustomStation={isCustomStation}
                        time={time}
                    />
                    <div className="player__section">
                        <div className="player__volume-bar">
                            <VolumeBar store={volumeBarStore} />
                        </div>
                        <div className="player__action-buttons">
                          <PlayerSongControls
                            model={nowPlayingData}
                            likedSongs={likedSongs}
                            handleNonAuthAction={handleNonAuthAction}
                            isAuth={isAuth}
                          />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});
