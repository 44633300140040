import "./PlayerSongControls.css";

import { observer } from "mobx-react-lite";
import { NowPlayingModel } from "entities/NowPlaying";
import { FavouriteModel } from "entities/Favourite";
import { makeFromObjectMap } from "shared/lib/makeFromObjectMap";
import { rmdIcons } from "shared/icons";

import { ISongActionsMap, ISongControlOption } from "./types";
import { ControlBtn } from "../ui/ControlBtn/ControlBtn";

type Props = {
  model: NowPlayingModel;
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
};

export const PlayerSongControls = observer(function SongControls({
  model,
  likedSongs,
  handleNonAuthAction,
  isAuth,
}: Props) {
  const getStationId = () => likedSongs
    .find(song => String(song.id) === model.song?.id)?.stationId;

  const handleLike = async (model: NowPlayingModel) => {
    if (!isAuth) {
      handleNonAuthAction();
      return;
    }
    await model.onToggleLike(getStationId());
  };

  const songActionsMap: ISongActionsMap = {
    share: {
      action: () => {},
      checkIsActive: () => false,
      isAvailable: false
    },
    like: {
      action: handleLike,
      checkIsActive: () => likedSongs.some(item=> (item.songId === model.song?.id)),
      isAvailable: isAuth,
    },
    download: {
      action: () => {},
      checkIsActive: () => false,
      isAvailable: false
    }
  };
  const songActions = makeFromObjectMap(songActionsMap);
  const onItemAction = (item: ISongControlOption) => item.action(model);

  return (
    <div className="player-song-controls">
      {songActions.map(({ key, item }) => <ControlBtn
        key={key}
        isAvailable={item.isAvailable}
        checkIsActive={item.checkIsActive}
        icon={key as keyof typeof rmdIcons}
        action={() => onItemAction(item)}
        isAuth={isAuth}
        model={model}
      />)}
    </div>
  );
});